/* This example requires Tailwind CSS v2.0+ */
import React from "react"

import Logo from "../../images/logo-ceos-noir.png"

const navigation = {
    main: [
      { name: 'Mentions légales', href: '/legal/mention-legales/' },
      { name: 'CGU', href: '/legal/cgu/' },
      { name: 'Politique de confidentialité', href: '/legal/politique-confidentialite/' },
      { name: 'Nous rejoindre', href: '/nous-contacter/' },
    ]
  }
  
  export default function Example() {
    return (
      <footer className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <p className="flex justify-center">
                <img src={Logo} alt="CEOS Avocats" className="grayscale" />
            </p>
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
            {navigation.main.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          
          <p className="mt-4 text-center text-base text-gray-400">
          <strong>Lyon/Villeurbanne</strong> - IMMEUBLE LE PALAIS D’HIVER 149 boulevard de Stalingrad - 69100 Villeurbanne<br />
          <strong>Nantes</strong> - 25 boulevard des Martyrs Nantais de la Résistance - 44200 Nantes<br /><br />


              &copy; 2022 - Tous Droits Réservés.
              </p>
        </div>
      </footer>
    )
  }
  